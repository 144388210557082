// 导入Vue
import { createRouter } from 'vue-router'
import { createWebHistory } from 'vue-router/dist/vue-router'

// 创建路由的实例对象，定义路由规则，不需要写#
let router = createRouter({
    history: createWebHistory(),
    routes: [
        // 重定向规则
        { path: '/', redirect: '/home' },
        // 分组路由
        { path: '/home', component: () => import('../src/components/pageHome.vue') },
        { path: '/home/phone', component: () => import('../src/components/pageHomePhone.vue') },
        // 产品列表
        { path: '/pdList', component: () => import('../src/components/pagePdList.vue') },
        { path: '/pdList/phone', component: () => import('../src/components/pagePdListPhone.vue') },
        // 产品说明
        { path: '/pd', component: () => import('../src/components/pagePd.vue') },
        { path: '/pd/phone', component: () => import('../src/components/pagePdPhone.vue') },
        // 介绍
        { path: '/about', component: () => import('../src/components/pageAbout.vue') },
        { path: '/about/phone', component: () => import('../src/components/pageAboutPhone.vue') },
        // 联系
        { path: '/contact', component: () => import('../src/components/pageContact.vue') },
        { path: '/contact/phone', component: () => import('../src/components/pageContactPhone.vue') },
    ],
    // 回到顶部
    scrollBehavior() {
        // 滚动到顶部
        return { top: 0 }
    }
})

// 对外共享路由的实例对象
export default router
