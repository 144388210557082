import { createApp } from 'vue'
import App from './App.vue'

const app = createApp(App)

// 使用路由
import router from '../router/index.js'
app.use(router)

// 导入异步加载模块
import axios from 'axios'
// 将后端地址预设为本地(开发环境)，实际操作时先进行一次判断，如果出现链接问题则将后端地址改为生产环境
axios.defaults.baseURL = 'https://www.poseidea.com'
axios.justle = 'production'
axios.get('http://127.0.0.1:7009/contact/debug').then(() => {
  axios.defaults.baseURL = 'http://127.0.0.1:7009'
  console.log('switch to development environment')
  axios.justle = 'development'
})

// icon
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}

// 挂载
app.mount('#app')
